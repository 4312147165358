const environments = {
    HealthpointFHIRProdPlan: {
        name: "HealthpointFHIRProdPlan",
        label: "Production",
        desc: "Healthpoint API Production",
        domain: "api",
        backgroundColor: 'rgba(0,255,0,0.05)',
        borderColor: 'rgba(0,255,0,1)',
    },
    HealthpointFHIRUATPlan: {
        name: "HealthpointFHIRUATPlan",
        label: "UAT",
        desc: "Healthpoint API User Acceptance Test",
        domain: "uat",
        backgroundColor: 'rgba(0,0,255,0.05)',
        borderColor: 'rgba(0,0,255,1)',
    },
    HealthpointFHIRUATEnhancedPlan: {
        name: "HealthpointFHIRUATEnhancedPlan",
        label: "UAT-Enhanced",
        desc: "Healthpoint API User Acceptance Test - Enahnced",
        domain: "uat",
        backgroundColor: 'rgba(0,0,255,0.05)',
        borderColor: 'rgba(0,0,255,1)',
    },
    HealthpointFHIRTestPlan: {
        name: "HealthpointFHIRTestPlan",
        label: "Test",
        desc: "Healthpoint API Test",
        domain: "test",
        backgroundColor: 'rgba(255,0,0,0.05)',
        borderColor: 'rgba(255,0,0,1)',
    },
    HealthpointFHIRDevPlan: {
        name: "HealthpointFHIRDevPlan",
        label: "Development",
        desc: "Healthpoint API Development",
        domain: "dev",
        backgroundColor: 'rgba(255,0,0,0.05)',
        borderColor: 'rgba(255,100,255,1)',
    },
    "healthpoint-fhir-api-prod": {
        name: "healthpoint-fhir-api-prod",
        label: "Production",
        desc: "Healthpoint API Production",
        domain: "api",
        backgroundColor: 'rgba(0,255,0,0.05)',
        borderColor: 'rgba(0,255,0,1)',
    },
    "healthpoint-fhir-api-uat": {
        name: "healthpoint-fhir-api-uat",
        label: "UAT",
        desc: "Healthpoint API User Acceptance Test",
        domain: "uat",
        backgroundColor: 'rgba(0,0,255,0.05)',
        borderColor: 'rgba(0,0,255,1)',
    },
    "healthpoint-fhir-api-uat-enhanced": {
        name: "healthpoint-fhir-api-uat-enhanced",
        label: "UAT-Enhanced",
        desc: "Healthpoint API User Acceptance Test - Enahnced",
        domain: "uat",
        backgroundColor: 'rgba(0,0,255,0.05)',
        borderColor: 'rgba(0,0,255,1)',
    },
    "healthpoint-fhir-api-test": {
        name: "healthpoint-fhir-api-test",
        label: "Test",
        desc: "Healthpoint API Test",
        domain: "test",
        backgroundColor: 'rgba(255,0,0,0.05)',
        borderColor: 'rgba(255,0,0,1)',
    },
    "healthpoint-fhir-api-dev": {
        name: "healthpoint-fhir-api-dev",
        label: "Development",
        desc: "Healthpoint API Development",
        domain: "dev",
        backgroundColor: 'rgba(255,0,0,0.05)',
        borderColor: 'rgba(255,100,255,1)',
    }
};
export default environments;


