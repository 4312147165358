import React from 'react';
import {Row, Col, Form} from "reactstrap";
import FormField from '../../components/terminal/FormField';
import {SchemaType, InputType} from '../../common/utils/constants';
import { Formik } from "formik";
import {flattenToValues} from "../../common/utils";
import { useLocation } from 'react-router';
import queryString from 'query-string';

// https://docs.aws.amazon.com/apigateway/api-reference/resource/api-key/

const formSchema = {
    $id: 'AccountDetailsForm',
    description: 'Account Details Form',
    type: SchemaType.object,
    properties: {
        email: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        },
        name: {
            label: 'Name',
            description: '',
            type: 'string',
            inputType: InputType.TEXT,
        },
        phone: {
            label: 'Phone number',
            description: '',
            type: 'tel',
            inputType: InputType.TEL,
        },
        organisation: {
            label: 'Organisation',
            description: 'Organisation',
            type: 'string',
            inputType: InputType.TEXT,
        },
        position: {
            label: 'Position',
            description: 'Position',
            type: 'string',
            inputType: InputType.TEXT,
        },
        message: {
            label: 'Your intended API usage',
            description: 'Let us know what you want from the API',
            type: 'string',
            inputType: InputType.TEXTAREA,
        },
        password: {
            label: 'Password',
            type: 'text',
            inputType: InputType.PASSWORD,
        },
        confirmPassword: {
            label: 'Confirm Password',
            type: 'text',
            inputType: InputType.PASSWORD,
        },
        tncAccepted: {
            label: 'T&Cs accepted date',
            type: 'date',
            inputType: InputType.DATE,
        }
    },
};

const AccountDetails = ({
    accountDetails,
    apiGw,
    ...props
}) => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const confirm = params.confirm;

    const accountDetailsFlattened = flattenToValues(accountDetails);

    return (
        <Formik
            initialValues={{
                isUserNotConfirmedInit: !!confirm,
                tncAccepted: apiGw.tncAccepted,
                ...accountDetailsFlattened,
            }}
            onSubmit={async (_values, _formikBag) => {}}
            validate={ _values => {}}
            validateOnChange
            validateOnBlur
        >
        {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                <Row>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['email']}
                            disabled
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['name']}
                            disabled
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['phone']}
                            disabled
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['organisation']}
                            disabled
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['position']}
                            disabled
                        />
                    </Col>
                    <Col md="4">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['tncAccepted']}
                            disabled
                        />
                    </Col>
                    <Col md="12">
                        <FormField
                            isRequired
                            {...props}
                            {...formikProps}
                            baseSchema={formSchema}
                            path={['message']}
                            disabled
                        />
                    </Col>
                </Row>
            </Form>
        )}
        </Formik>
    );
};

export default AccountDetails;
