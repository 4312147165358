import React from 'react';
import styled from 'styled-components';
import LoadingOverlay from '@speedy4all/react-loading-overlay';

const LoadingStyle = styled(LoadingOverlay)`
  position: fixed;
  margin: auto;
  align: middle;
  width: 100%;
  verticalAlign: middle;
  .MyLoader_overlay {
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const Spinner = () => (
    <img alt="Tao of Healthpoint" src="/hp-spinner.gif"/>
);
export const Loading = () => (
    <LoadingStyle
        className="d-flex justify-content-center"
        active={true}
        classNamePrefix='MyLoader_'
        spinner = {<Spinner/>}
    >
        <div style={{height:"50vh"}}>
            <p className={"p-4"}>Loading ...</p>
        </div>
    </LoadingStyle>
);

const whileLoading = isLoading => Right => (isLoading ? <Loading /> : Right);

export default whileLoading;
