import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { mapIndexed } from '../../common/utils';

const SelectRadio = ({ name, value, options }) => {
  const onChangeRadio = useCallback(
    idx => () => {
      const value = props.options[idx].value;
      props.onChange(value);
    },
    [props]
  );
  return (
  <div>
    {mapIndexed(
      (opt, idx) => (
        <FormGroup check key={opt.label}>
          <Label check>
            <Input
              type="radio"
              name={name}
              value={opt.value === value}
              checked={opt.value === value}
              onChange={onChangeRadio(idx)}
            />
            {` ${opt.label}`}
          </Label>
        </FormGroup>
      ),
      options
    )}
  </div>);
};

SelectRadio.PropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};
export default SelectRadio;
