import { Container, Row, Col, Form, Button, Tooltip, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import {StyledForm, StyledContainer, TrForEnv} from '../../style';
import {InputType, SchemaType} from "../../common/utils/constants";
import * as R from "ramda";
import React, { useState } from 'react';

const formSchema = {
    $id: 'UsersForm',
    description: 'Search for users',
    type: SchemaType.object,
    properties: {
        filter: {
            label: 'filter',
            description: 'Filter',
            type: 'text',
            inputType: InputType.TEXT,
        },
    },
};

const Th = ({ title, doc, children, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <th>
            <span>
                <strong>{title}</strong>
                <i id={title} className="p-2 fa fa-info-circle">
                    <Tooltip placement="top" isOpen={isOpen} target={title} toggle={() => setIsOpen(!isOpen)}>
                        {children}
                    </Tooltip>
                </i>
            </span>
        </th>
    );
};

const ThEnv = ({ env, children, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <th>
            <span>
                <Link id={env.label} to={`/open-api?env=${env.name}`}>{env.label}</Link>
                <Tooltip placement="top" isOpen={isOpen} target={env.label} toggle={() => setIsOpen(!isOpen)}>
                    Click to open in Open API.
                </Tooltip>
            </span>
        </th>
    );
};

const UserRow = ({ user }) => {
    const { userPoolId, region } = window.config;
    return (
        <React.Fragment>
            <TrForEnv>
                <td>
                    {R.path(["cUser", "Username"], user) &&
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={encodeURI(`https://${region}.console.aws.amazon.com/cognito/users/?region=${region}#/pool/${userPoolId}/users/${user.cUser.Username}`)}>
                        {user.cUser.Username}
                    </a>}
                </td>
                <td>
                    {R.path(["dUser", "ApiKeyId"], user) &&
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href={encodeURI(`https://${region}.console.aws.amazon.com/apigateway/home?region=${region}#/api-keys/${user.dUser.ApiKeyId}`)}>
                        {user.dUser.ApiKeyId}
                    </a>}
                </td>
                <td>
                    {R.path(["dUser", "TncAccepted"], user)}
                </td>
                <td>
                    {R.path(["dUser", "UatDate"], user)}
                </td>
                <td>
                    {R.path(["dUser", "ProdDate"], user)}
                </td>
            </TrForEnv>
        </React.Fragment>
    );
};

const LoadingTable = ({ users, isLoading }) => (
    <Table>
        <thead>
        <tr>
            <Th title={"Email"}>
                <p>Email (Cognito userame)</p>
            </Th>
            <Th title={"ApiKeyId"}>
                <p>API key ID</p>
            </Th>
            <Th title={"TncDate"}>
                <p>When T&amp;Cs accepted.</p>
            </Th>
            <Th title={"UatDate"}>
                <p>UAT date</p>
            </Th>
            <Th title={"ProdDate"}>
                <p>Production date</p>
            </Th>
        </tr>
        </thead>
        <tbody>
        {users.filter(x => !!x).map((user, key) => (
            <UserRow {...{...user, key}} />
        ))}
        </tbody>
    </Table>
);

const Component = ({ users, errorCode, isLoading, handleSubmit, ...props }) => {
    const { stage, userPoolId, region } = window.config;
    return (
        <StyledContainer>
            <h1>API Users</h1>
            <hr/>
            <StyledForm className="p-0">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/apigateway/home?region=${region}#/api-keys`)}>
                                API Keys
                            </a>
                        </Col>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/cognito/users/?region=${region}#/pool/${userPoolId}/users/`)}>
                                Cognito
                            </a>
                        </Col>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/dynamodb/home?region=${region}#tables:selected=healthpoint-fhir-portal-${stage}-customers;tab=items`)}>
                                Dynamo Users
                            </a>
                        </Col>
                        <Col md="2">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={encodeURI(`https://${region}.console.aws.amazon.com/sns/v3/home?region=${region}#/topic/arn:aws:sns:${region}:851453851513:healthpoint-fhir-portal-${stage}`)}>
                                SNS
                            </a>
                        </Col>
                        <Col md="2">
                            <Button type={'submit'}>Load</Button>
                        </Col>
                    </Row>
                </Form>
            </StyledForm>
            <Container>
                <LoadingTable users={users} isLoading={isLoading} />
            </Container>
        </StyledContainer>
    );
};

export default Component;
