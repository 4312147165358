import React, { useState } from 'react';
import {Container, Row, Col, Form, Button, Alert} from 'reactstrap';
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as EmailValidator from "email-validator";
import {StyledForm,StyledContainer} from '../../style';
import { cognitoLogin } from "../../common/state/ducks/awsCognito/actions";
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import { useDispatch } from 'react-redux';

const formSchema = {
    $id: 'LoginForm',
    description: 'Log in to Healthpoint FHIR API',
    type: SchemaType.object,
    properties: {
        username: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        },
        password: {
            label: 'Password',
            description: 'Password',
            type: 'password',
            inputType: InputType.PASSWORD,
        }
    },
};

const LoginPage = ({
    ...props
}) => {
    let [errorCode, setErrorCode] = useState(undefined);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
            }}
            onSubmit={async (values, _formikBag) => {
                const {error}  = await new Promise((resolve, reject) => {
                    dispatch(cognitoLogin({...values, resolve, reject}));
                });
                console.dir({ error });
                if (error) {
                    setErrorCode(error.code);
                } else {
                    navigate("/dashboard");
                }
            }}
            validate={values => {
                const errors = {} ;
                if (!values.username || !EmailValidator.validate( values.username) ) {
                    errors.username = "Please enter a valid e-mail address";
                }
                return errors;
            }}
            validateOnChange
            validateOnBlur
        >
        {formikProps => {
            const disabled = !formikProps.isValid || !formikProps.dirty;
            return (
                <StyledContainer>
                    <h1>Login</h1>
                    <hr/>
                    <p>
                        Log in to view your API key, accessible APIs and usage or else
                        {' '}<NavLink to="/register">register</NavLink> if you have not already.
                    </p>
                    {errorCode==="UserNotConfirmedException" &&
                        <Alert color="warning">
                            <h4 className="alert-heading text-center">
                                User not confirmed
                            </h4>
                            <p className="text-center">
                                Please go to your
                                {' '}<a target="_blank" rel="noopener noreferrer" href={`mailto:${formikProps.values.email}`}>email</a>{' '}
                                and click the verification link.
                            </p>
                        </Alert>
                    }
                    {errorCode === "UserNotFoundException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            User not found
                        </h4>
                        <p className="text-center">
                            Please <NavLink to="/register">register</NavLink>  before logging in.
                        </p>
                    </Alert>
                    }
                    {errorCode === "NotAuthorizedException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            Invalid password
                        </h4>
                        <p className="text-center">
                            If you have forgotten your password you may reset it <NavLink to="/password-reset">here</NavLink>.
                        </p>
                    </Alert>
                    }
                    {errorCode === "PasswordResetRequiredException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            A password reset is required
                        </h4>
                        <p className="text-center">
                            You can do that <NavLink to="/password-reset">here</NavLink>.
                        </p>
                    </Alert>
                    }
                    <Container className="p-0">
                        <Row key={1}>
                            <Col>
                                <StyledForm className="p-3">
                                    <Form onSubmit={formikProps.handleSubmit}>
                                        <Row>
                                            <Col md="12">
                                                <FormField
                                                    {...props}
                                                    {...formikProps}
                                                    baseSchema={formSchema}
                                                    path={['username']}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormField
                                                    {...props}
                                                    {...formikProps}
                                                    baseSchema={formSchema}
                                                    path={['password']}
                                                />
                                            </Col>
                                        </Row>
                                        <Button disabled={disabled} type={'submit'}>Login</Button>
                                    </Form>
                                </StyledForm>
                            </Col>
                        </Row>
                    </Container>
                    <br/>
                    <p>
                        If you have forgotten your password click <NavLink to="/password-reset">here</NavLink>.
                    </p>
                </StyledContainer>
            );
        }}
        </Formik>
    );
};

export default LoginPage;
