import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const SelectOption = ({ id, name, value, onChange, onBlur, disabled, options }) => (
  <select
    {...{
      id,
      name,
      value: value || '',
      onChange,
      onBlur,
      className: 'form-control',
      disabled,
    }}
  >
    {R.map(
      opt => (
        <option key={opt.value || 'Null'} value={opt.value || ''}>
          {opt.label}
        </option>
      ),
      [{ label: 'Choose...' }].concat(options)
    )}
  </select>
);

SelectOption.PropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

export default SelectOption;
