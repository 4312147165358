import React, { useState } from 'react';
import * as EmailValidator from "email-validator";
import { redirect, useLocation} from "react-router";
import { Formik } from "formik";
import {Container, Row, Col, Form, Button, Alert} from 'reactstrap';
import { NavLink, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import {cognitoPasswordChange} from "../../common/state/ducks/awsCognito/actions";
import {StyledContainer, StyledForm} from "../../style";
import {passwordSchema} from '../../common/utils';
import { useDispatch } from 'react-redux';

const formSchema = {
    $id: 'PasswordChangeForm',
    description: 'Change password',
    type: SchemaType.object,
    properties: {
        code: {
            label: 'Confirmation code',
            type: 'text',
            inputType: InputType.TEXT,
        },
        password: {
            label: 'Password',
            type: 'password',
            inputType: InputType.PASSWORD,
        },
        confirmPassword: {
            label: 'Confirm password',
            type: 'password',
            inputType: InputType.PASSWORD,
        },
    },
};

const Page = ({ disabled }) => {
    let [errorCode, setErrorCode] = useState(undefined);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const initialValues = queryString.parse(location.search);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, _formikBag) => new Promise((resolve,reject) => {
                dispatch(cognitoPasswordChange({...values, resolve, reject}));
            }).then(({error}) => {
                if (error) {
                    setErrorCode(error.code);
                } else {
                    navigate("/login");
                }
            })}
            validate={values => {
                const errors = {};
                if (!values.code ) {
                    errors.code = "Verifcation code is required";
                }
                if (!values.password) {
                    errors.password = "Password is required";
                } else if (values.password && !passwordSchema.validate(values.password)) {
                    errors.password = "Invalid password";
                } else if (values.password && !values.confirmPassword) {
                    errors.confirmPassword = "Please confirm password";
                } else if (values.password !== values.confirmPassword) {
                    errors.confirmPassword = "Passwords don't match";
                }
                return errors;
            }}
            validateOnChange
            validateOnBlur
        >
        {formikProps => {
            if (!formikProps.values.email || !EmailValidator.validate(formikProps.values.email)) {
                return redirect("/password-reset");
            };

            return (
                <StyledContainer>
                    <h1>Change Password</h1>
                    <hr/>
                    <p>
                        Please enter the confirmation code sent to your email{' '}
                        <a target="_blank" rel="noopener noreferrer" href={`mailto:${formikProps.values.email}`}>{formikProps.values.email}</a>,
                        and change your password.
                    </p>
                    <p>
                        Check your spam/junk email folders if you do not receive this email in the next few minutes.
                    </p>
                    {errorCode==="CodeExpiredException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            Verification code is invalid.
                        </h4>
                        <p className="text-center">
                            Please <NavLink to="/password-reset">reset</NavLink> your password again.
                        </p>
                    </Alert>
                    }
                    {errorCode==="CodeMismatchException" &&
                    <Alert color="warning">
                        <h4 className="alert-heading text-center">
                            Verification code is invalid.
                        </h4>
                    </Alert>
                    }
                    <Container className="p-0">
                        <Row key={1}>
                            <Col>
                                <StyledForm className="p-3">
                                    <Form onSubmit={formikProps.handleSubmit}>
                                        <Row>
                                            <Col md="12">
                                                <FormField
                                                    {...formikProps}
                                                    baseSchema={formSchema}
                                                    path={['code']}
                                                />
                                                <FormField
                                                    {...formikProps}
                                                    baseSchema={formSchema}
                                                    path={['password']}
                                                />
                                                <FormField
                                                    {...formikProps}
                                                    baseSchema={formSchema}
                                                    path={['confirmPassword']}
                                                />
                                            </Col>
                                        </Row>
                                        <Button disabled={disabled} type={'submit'}>Change</Button>
                                    </Form>
                                </StyledForm>
                            </Col>
                        </Row>
                    </Container>
                </StyledContainer>
            );
        }}
        </Formik>
    );
};
export default Page;

