import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router';
import rootSaga from './sagas';
import * as reducers from './ducks';
import {history} from '../../common/utils';

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
});

// console.dir({reducers});

const createRootReducer = history => ({
  // this `router` key must be the same as
  // `@lagunovsky/redux-react-router.ROUTER_REDUCER_MAP_KEY``
  router: createRouterReducer(history),
  toastr: toastrReducer,
  ...reducers,
});

export const setupStore = () => {
  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware()
        .concat([
          sagaMiddleware,
          loggerMiddleware,
          createRouterMiddleware(history) // for dispatching history actions
        ]);

      return middleware;
    },
  });
  sagaMiddleware.run(rootSaga);

  // hot reloading: https://medium.com/@dan_abramov/hot-reloading-in-react-1140438583bf
  if (module.hot) {
    module.hot.accept('./ducks', () => {
      const nextReducer = require('./ducks').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }
  return store;
};
