import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { cognitoLogout } from '../common/state/ducks/awsCognito/actions';
import { useDispatch, useSelector } from 'react-redux';

const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdminUser = useSelector( (state) => state.cognito.isAdminUser )
    const isAuthenticated = useSelector( (state) => state.cognito.isAuthenticated )
    const tncsAccepted = useSelector( (state) => state.apiGw.tncAccepted )
    const isActive = useSelector( (state) => state.apiGw.isActive )
    // cognitoLogout
    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <div className="ui fluid container d-flex justify-content-start p-1">
                <a className="navbar-brand py-1 mx-2" href="/#">
                    <img className="img-fluid"
                         alt="Healthpoint Logo"
                         src="/logo-healthpoint.png"
                         aria-hidden="true"
                         style={{paddingRight: "10px", height: "80px"}}
                    />
                </a>
                <button className="navbar-toggler my-2" type="button" data-toggle="collapse"
                        data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="flex-column flex-grow-1" id="navbarCollapse">
                    <ul className="navbar-nav">
                        <h1 style={{color: "white"}}>Healthpoint API Gateway</h1>
                    </ul>
                    <ul className="navbar-nav flex-row mb-md-1 mt-md-0 mb-3 mt-2">
                        <li className="px-3 nav-item">
                            <NavLink end to={"/"}>Home</NavLink>
                        </li>
                        <li className="px-3 nav-item">
                            <NavLink end to={"/get-started"}>Get Started</NavLink>
                        </li>
                        <li className="px-3 nav-item">
                            <NavLink end to={"/api-overview"}>Overview</NavLink>
                        </li>
                        <li className="px-3 nav-item mr-auto">
                            <NavLink end to={"/updates"}>Updates</NavLink>
                        </li>
                        {isAuthenticated &&  isAdminUser  && <li className="px-3 nav-item">
                            <NavLink end to={"/admin"}>Admin</NavLink>
                        </li>}
                        {isAuthenticated && tncsAccepted && isActive  && <li className="px-3 nav-item">
                            <NavLink end to={"/open-api"}>Open API</NavLink>
                        </li>}
                        {isAuthenticated && tncsAccepted && isActive  && <li className="px-3 nav-item">
                            <NavLink end to={"/documentation"}>Documentation</NavLink>
                        </li>}
                        {isAuthenticated && <li className="px-3 nav-item">
                            <NavLink end to={"/dashboard"}>Dashboard</NavLink>
                        </li>}
                        {isAuthenticated && !tncsAccepted && <li className="px-3 nav-item">
                            <NavLink end to={"/terms-and-conditions"}>T&Cs</NavLink>
                        </li>}
                        {isAuthenticated && <li className="px-3 nav-item">
                            <NavLink end
                                     to={"/"}
                                onClick={() => {
                                    dispatch(cognitoLogout());
                                    navigate("/");
                                }}>
                                    Logout
                            </NavLink>
                        </li>}
                        {!isAuthenticated && <li className="px-3 nav-item">
                            <NavLink to={"/register"}>Register</NavLink>
                        </li>}
                        {!isAuthenticated && <li className="px-3 nav-item">
                            <NavLink to={"/login"}>Login</NavLink>
                        </li>}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
