import React from 'react';
import { Formik } from "formik";
import {Container, Row, Col, Form, Button} from 'reactstrap';
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import queryString from 'query-string';
import {InputType, SchemaType} from "../../common/utils/constants";
import FormField from '../../components/terminal/FormField';
import {StyledForm,StyledContainer} from '../../style';
import { cognitoPasswordReset } from "../../common/state/ducks/awsCognito/actions";
import { useDispatch } from 'react-redux';

const formSchema = {
    $id: 'PasswordResetForm',
    description: 'Log in to Healthpoint FHIR API',
    type: SchemaType.object,
    properties: {
        email: {
            label: 'E-mail',
            description: 'E-mail',
            type: 'email',
            inputType: InputType.EMAIL,
        }
    },
};

const Page = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <Formik
            initialValues={{
                email: '',
            }}
            onSubmit={async (values, _formikBag) => {
                const result = await new Promise((resolve, reject) => {
                    dispatch(cognitoPasswordReset({ ...values, resolve, reject }));
                });
                const { email } = result;
                const params = { email };
                const query = queryString.stringify(params);
                const targetUrl = `/password-change?${query}`;
                console.dir({ params, query, targetUrl });
                navigate(targetUrl);
            }}
            validate={(values, _formikBag) => {
                const errors = {} ;
                if (!values.email || !EmailValidator.validate(values.email) ) {
                    errors.email = "Please enter a valid e-mail address";
                }
                return errors;
            }}
            validateOnChange
            validateOnBlur
        >
        {formikProps => (
            <StyledContainer>
                <h1>Password Reset</h1>
                <hr/>
                <p>
                    Please enter your e-mail.
                </p>
                <Container className="p-0">
                    <Row key={1}>
                        <Col>
                            <StyledForm>
                                <Form onSubmit={formikProps.handleSubmit}>
                                    <Row>
                                        <Col md="12">
                                            <FormField
                                                {...formikProps}
                                                baseSchema={formSchema}
                                                path={['email']}
                                            />
                                        </Col>
                                    </Row>
                                    <Button disabled={formikProps.disabled} type={'submit'}>Reset</Button>
                                </Form>
                            </StyledForm>
                        </Col>
                    </Row>
                </Container>
            </StyledContainer>
        )}
        </Formik>
    );
};
export default Page;

