import React, { useState } from 'react';
import RegistrationPage from './RegistrationPage';
import RegistrationResultPage from './RegistrationResultPage';


const Page = () => {
    let [isRegistered, setIsRegistered] = useState(undefined);

    return (
        <div className="py-3 container">
            {!isRegistered && <RegistrationPage {...{isRegistered, setIsRegistered}} />}
            {isRegistered && <RegistrationResultPage {...{isRegistered, setIsRegistered}} />}
         </div>
    );
};

export default Page ;
