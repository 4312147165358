import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import queryString from "query-string";
import * as R from "ramda";
import {ThemeProvider} from "styled-components";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {StyledContainer,DivForEnv} from "../../style";
import whileLoading from "../../components/whileLoading";
import environments from "../../common/utils/environments";
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

/*
 * Large payloads cause page to freeze
 *
 * https://github.com/swagger-api/swagger-ui/issues/8404
 */
SwaggerUI.config.defaults.syntaxHighlight.activated = false;

const Component = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const apiGw = useSelector(state => state.apiGw);

    let [usagePlanName, setUsagePlanName] = useState(params.env || apiGw.usagePlans[0].name);

    const apiKey = R.path(["user","apiKey", "value"], apiGw);
    const env = environments[usagePlanName];
    const url = `/openapi-healthpointapi-${env.domain}.yaml`;
    return whileLoading(R.isEmpty(apiGw.usagePlans))(
        <StyledContainer id={"top"}>
            <h1>Try it with Open API</h1>
            <p>
                You can explore the Healthpoint API defined by the{' '}
                <a
                    target="_blank" rel="noopener noreferrer"
                    href={"https://swagger.io/docs/specification/about/"}>
                    OpenAPI
                </a>{' '}
                specification.
            </p>
            <ol>
                <li>
                    <p>
                        Choose your environment:
                        <select onChange={e => setUsagePlanName(e.target.value)}
                                value={usagePlanName}
                                className="custom-select"
                                id="inputGroupSelect01">
                            {apiGw.usagePlans.map((x, i) => (
                                <option key={i} value={x.name}>{x.description}</option>
                            ))}
                        </select>
                    </p>
                </li>
                <li>
                    <p>
                        Enter you API key <code>{apiKey}</code> with the <code>Authorize</code> button and click "Close".
                    </p>
                </li>
                <li>
                    <p>
                        Click into the resource you are interested in. For most use cases it will be HealthcareService and then click on GET /HealthcareService.
                    </p>
                </li>
                <li>
                    <p>
                        Click on “Try it out”
                    </p>
                </li>
                <li>
                    <p>
                        Select the parameters you are interested in from the dropdowns or enter text as appropriate. Make sure you read the description as some fields only occur in some branches. See <Link smooth to="/documentation#api-usage">here</Link> for a detailed desciption of the API.
                    </p>
                </li>
                <li>
                    <p>
                        Click Execute
                    </p>
                </li>
            </ol>
            <br/>
            <ThemeProvider theme={env}>
                <div className="card m-1 p-3">
                    <DivForEnv>
                        <SwaggerUI url={url} deepLinking={false}/>
                    </DivForEnv>
                </div>
            </ThemeProvider>
        </StyledContainer>
    );
};
export default Component;
