import * as R from "ramda";
import React, { useEffect } from 'react';
import Chart from 'chart.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import whileLoading from "../../components/whileLoading";
import environments from "../../common/utils/environments";
const moment = extendMoment(Moment);

const UsageChart = ({
    usages,
    startDate,
    endDate,
}) => {
    const drawChart = () => {
        const range = moment.range(startDate, endDate);
        const dates = Array.from(range.by('days'));
        const labels = dates.map(dt => dt.format('YYYY-MM-DD'));
        const datasets = usages.map( x => {
            if (R.isEmpty(x.items)) {
                return {};
            }
            const usagePlanName = Object.keys(x.items)[0];
            const series  = x.items[usagePlanName];
            console.dir({ usagePlanName,series });
            return {
                data: series.map( (x,i) => x[0]),
                borderWidth: 1,
                ...environments[x.name]
            };
        }).filter(x => !R.isEmpty(x));
        const ctx = document.getElementById("usages=chart");
        new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets,
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    ]
                }
            }
        });
    }

    useEffect(
        () => drawChart(usages, startDate, endDate),
        [usages, startDate, endDate]
    );

    return whileLoading(R.isNil(usages))(
        <canvas id="usages=chart" />
    );
};

export default UsageChart;
