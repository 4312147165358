import React from 'react';
import {Link} from "react-router-dom";
import {StyledContainer} from "../../style";

const Component = () => (
    <StyledContainer>
        <h1>Get Started</h1>
        <hr/>
        <ol>
            <li><Link to={"/register"}>Register</Link>
                {' '}your interest. This is a short form providing your contact details and what you would like
                to use the API for.
            </li>
            <li>A member of our team will contact you to determine your requirements and provide your authentication
                access.
            </li>
            <li><Link to={"/login"}>Sign in</Link>{' '} and accept the T&Cs.</li>
            <li>Read the documentation and view samples.</li>
            <li>Start playing in our UAT (user acceptance testing) environment.</li>
            <li>Use the Healthpoint API in production in your application/website.
                <strong>Please ensure you reference that Healthpoint is the source of information anywhere that you use it.
                Example text: "This specialist profile information has been provided by https://www.healthpoint.co.nz,
                helping people better understand and use New Zealand health services."</strong>
            </li>
        </ol>
        <p>Lost your API Key ? Go to your <Link to={"/dashboard"}>Dashboard</Link>.</p>
        <hr/>
    </StyledContainer>
);
export default Component;
