import {Table,Tooltip} from "reactstrap";
import React, { useState } from "react";
import * as R from 'ramda';
import {Link } from 'react-router-dom';
import environments from "../../common/utils/environments";
import {TrForEnv} from "../../style";

const Th = ({
    title,
    doc,
    children,
    ...props
}) => {
    let [isOpen, setIsOpen] = useState(false);
    return (
        <th>
            <span>
                <strong>{title}</strong>
                <i id={title} className="p-2 fa fa-info-circle">
                    <Tooltip placement="top" isOpen={isOpen} target={title} toggle={()=>setIsOpen(!isOpen)}>
                        {children}
                    </Tooltip>
                </i>
            </span>
        </th>
    );
};

const ThEnv = ({
    env,
    children,
    ...props
}) => {
    let [isOpen, setIsOpen] = useState(false);
    return (
        <th>
            <span>
                <Link id={env.label} to={`/open-api?env=${env.name}`}>{env.label}</Link>
                <Tooltip placement="top" isOpen={isOpen} target={env.label} toggle={()=>setIsOpen(!isOpen)}>
                    Click to open in Open API.
                </Tooltip>
            </span>
        </th>
    );
};

const EnvironmentRow = ({
    usagePlan,
}) => {
    const env = environments[usagePlan.name];
    if (!env) {
        // See `environments.js`.
        console.error(`Usage plan: '${usagePlan.name}' is not defined.`);
    }
    console.dir({environments, usagePlan, env});
    return (
        <React.Fragment>
            <TrForEnv theme={env}>
                <ThEnv scope="row" env={env}>
                    <p>Click to open Open API</p>
                </ThEnv>
                <td>{usagePlan.description}</td>
                <td>{R.path(["throttle","rateLimit"],usagePlan)}</td>
                <td>{R.path(["throttle","burstLimit"],usagePlan)}</td>
                <td>{R.path(["quota","limit"],usagePlan)}</td>
            </TrForEnv>
        </React.Fragment>
    );
};

const Environments = ({
    usagePlans,
}) => (
    <Table>
        <thead>
        <tr>
            <th>Name</th>
            <th>Description</th>
            <Th title={"Rate"}>
                <p>Limit on the average number of requests per second over an extended period of time using the
                    <a target="_blank" rel="noopener noreferrer" href={"https://en.wikipedia.org/wiki/Token_bucket"}>{' '}
                        token bucket algorithm
                    </a>
                </p>
            </Th>
            <Th title={"Burst"}>
                <p>Burst buffer size. The capacity of the{' '}
                    <a target="_blank" rel="noopener noreferrer" href={"https://en.wikipedia.org/wiki/Token_bucket"}>
                        token bucket
                    </a>.
                </p>
            </Th>
            <Th title={"Quota"}>
                <p>Limit on the total number of requests per day.</p>
            </Th>
        </tr>
        </thead>
        <tbody>
        {usagePlans.map(usagePlan => (
            <EnvironmentRow {...{key: usagePlan.id, usagePlan}} />
        ))}
        </tbody>
    </Table>
);

export default Environments;
