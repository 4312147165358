import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Formik } from "formik";
import {Col, Form, Row} from "reactstrap";
import {StyledContainer,StyledForm} from "../../style";
import {apiGwApiUsageGet} from "../../common/state/ducks/awsApiGw/actions";
import {InputType, SchemaType} from "../../common/utils/constants";
import UsageChart from "./UsageChart";
import FormField from '../../components/terminal/FormField';
import { useDispatch } from 'react-redux';


const formSchema = {
    $id: 'ApiUsageForm',
    description: 'Filter usage',
    type: SchemaType.object,
    properties: {
        startDate: {
            label: 'Start Usage Date',
            description: '',
            type: 'date',
            inputType: InputType.DATE,
        },
        endDate: {
            label: 'End Usage Date',
            description: '',
            type: 'date',
            inputType: InputType.DATE,
        },
    },
};

const Usage = ({
    usagePlans,
}) => {
    const dispatch = useDispatch();

    let [startDate, setStartDate] = useState(moment().subtract(1, 'month').toDate());
    let [endDate, setEndDate] = useState(moment().add(1, 'day').startOf('day').toDate());
    let [usages, setUsages] = useState(null);
    const getUsages = (startDate, endDate) => {
        new Promise((resolve, reject) => {
            dispatch(apiGwApiUsageGet({
                usagePlans,
                startDate,
                endDate,
                resolve,
                reject,
            }));
        }).then(usages => {
            setUsages(usages);
        });
    };
    const schemaFieldChanged = () => (fieldName, value) => {
        switch (fieldName) {
        case 'startDate':
            setStartDate(value);
            break;
        case 'endDate':
            setEndDate(value);
            break;
        }
        console.dir({ startDate, endDate ,fieldName, value});
        getUsages(startDate, endDate);
    };

    useEffect(() => getUsages(startDate, endDate), []);

    return (
        <StyledContainer>
            {usages &&
            <UsageChart {...{
                usages,
                startDate,
                endDate,
            }}/>}
            <Formik
                initialValues={{
                    startDate,
                    endDate,
                }}
                onSubmit={async (values, _formikBag) => {
                    const { startDate, endDate } = values;
                    getUsages(startDate, endDate);
                }}
                validate={ _values => {
                    const errors = {};
                    return errors;
                }}

                validateOnChange
                validateOnBlur
            >
            {formikProps => (
                <StyledForm>
                    <Form onSubmit={formikProps.handleSubmit} autoComplete="on">
                        <Row>
                            <Col md="4">
                                <FormField
                                    disabled
                                    {...formikProps}
                                    baseSchema={formSchema}
                                    path={['startDate']}
                                    schemaFieldChanged={schemaFieldChanged}
                                />
                            </Col>
                            <Col md="4">
                                <FormField
                                    disabled
                                    {...formikProps}
                                    baseSchema={formSchema}
                                    path={['endDate']}
                                    schemaFieldChanged={schemaFieldChanged}
                                />
                            </Col>
                        </Row>
                    </Form>
                </StyledForm>
            )}
            </Formik>
        </StyledContainer>
    );
};

export default Usage;
