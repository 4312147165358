import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

const SelectCreatable = ({ createOption, isClearable, ...props }) => {
  const [options] = useState();
  if (createOption) {
    return <ReactSelect.Creatable {...{ ...props, isClearable, className: 'check-select', createOption }}/>;
  } else {
    return <ReactSelect {...{ ...props, isClearable, className: 'check-select' }} />;
  }
};

SelectCreatable.PropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  isClearable: PropTypes.bool,
}

export default SelectCreatable;
