import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import * as R from "ramda";
import DataSamples from './DataSamples';
import ApiSamples from './ApiSamples';
import ApiPaging from './ApiPaging';

const Component = ({
}) => {
    const apiGw = useSelector(state => state.apiGw);

    const apiKey = R.pathOr("INSERT-API-KEY-HERE", ["user", "apiKey", "value"], apiGw);

    return (
        <div>
            <h1>The Healthpoint Data</h1>
            <div>
                <p>The Healthpoint website contains a large amount of detailed information that is predominantly
                    presented in a <strong style={{color: "#3DAE64"}}>service</strong>-centric context.</p>

                <p>There are currently over <strong style={{color: "#3DAE64"}}>8,700 services</strong> profiled in
                    Healthpoint with more than an associated <strong style={{color: "#3DAE64"}}>11,900</strong> locations (service-location).
                    Over &nbsp;<strong style={{color: "#3D87AE"}}>5,900 unique location</strong> pages (including hospitals) and
                    over &nbsp;
                    <strong style={{color: "#643DAE"}}>5,900 health practitioners</strong> are associated with the services
                    (over <strong style={{color: "#643DAE"}}>8,600 practitioner roles</strong>).
                    Subscription service pages contain detailed information about their services, procedures and
                    treatments and programmes.
                    This adds up to over 400,000 webpages of information about health services, practitioners, locations,
                    hospitals, services, procedures, treatments and programmes that services provide, all of which is
                    available in the API.
                </p>
                <p>To make it easier to navigate this content we have grouped this information
                    and provided recommended methods for how you can start calling the API in the sections below.
                </p>
                <ul>
                    <li><Link smooth to="/documentation#query-parameters">Query parameter value items and definitions</Link></li>
                    <li><Link smooth to="/documentation#data-model">The Healthpoint use of the HL7<sup>&reg;</sup> FHIR<sup>&reg;</sup> Standard Data Model</Link></li>
                    <li><Link smooth to="/documentation#field-documentation">Field Documentation</Link></li>
                    <li><Link smooth to="/documentation#api-usage">Recommended methods for using the API</Link></li>
                    <li><Link smooth to="/documentation#api-samples">How many results will I get per call in the API</Link></li>
                    <li><Link smooth to="/documentation#data-samples">Data Samples</Link></li>
                </ul>
            </div>
            <br/>
            <br/>
            <div>
                <h1 id="data-model">The Healthpoint HL7<sup>&reg;</sup> FHIR<sup>&reg;</sup> Data Model</h1>
                <p>
                    Please read the <strong><Link to="/api-overview">API Overview</Link></strong>{' '}
                    first for a summary of Healthpoint and FHIR<sup>&reg;</sup> .
                    As outlined in the overview, Healthpoint provides its content using the FHIR<sup>&reg;</sup> standard{' '}
                    <strong style={{color: "#3DAE64"}}>Healthcare service</strong>,{' '}
                    <strong style={{color: "#3D87AE"}}>Location</strong>,{' '}
                    <strong style={{color: "#643DAE"}}>Practitioner</strong> and{' '}
                    <strong style={{color: "#9D3DAE"}}>PractitionerRole</strong> resources.</p>
                <p>The Healthpoint data model is aligned to the FHIR<sup>&reg;</sup> data model. To facilitate ease of use Healthpoint
                    have included extensions so in most instances users will find everything they need in the
                    Healthcare Service resource, as this has been extended to include key location and practitioner
                    information. There is also an extension directly linking Healthcare service and
                    Practitioner resources.</p>
                <img src="/DataModel.jpeg" alt="Healthpoint FHIR Data model"/>
                <br/>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="field-documentation">Field Documentation</h1>
                <ul>
                    <li>
                        <a href="/documentation/Healthpoint_FHIR_Healthcare_Service_Field_Documentation.pdf" target="_blank" rel="noopener noreferrer">Healthcare service resource</a>
                    </li>
                    <li>
                        <a href="/documentation/Healthpoint_FHIR_Location_Field_Documentation.pdf" target="_blank" rel="noopener noreferrer">Location resource</a>
                    </li>
                    <li>
                        <a href="/documentation/Healthpoint_FHIR_Practitioner_Field_Documentation.pdf" target="_blank" rel="noopener noreferrer">Practitioner resource</a>
                    </li>
                    <li>
                        <a href="/documentation/Healthpoint_FHIR_PractitionerRole_Field_Documentation.pdf" target="_blank" rel="noopener noreferrer">PractitionerRole resource</a>
                    </li>
                </ul>
                <p>To view samples in JSON or XML for each branch and their associated resources, please go to samples.</p>
                <p>
                    * With the exception of when a location is a public or private hospital, all Location information from the Location resource will be found in the Healthcare service resource.
                </p>
                <p><strong>Additional resources to support the Healthpoint Field documentation:</strong></p>
                <ul>
                    <li>The Healthpoint API is documented according to the FHIR<sup>&reg;</sup> R4 standard described here: <a href="https://hl7.org/fhir">https://hl7.org/fhir</a>
                    </li>
                    <li>Field documentation format descrriptions: <a href="https://hl7.org/fhir/formats">https://hl7.org/fhir/formats</a>
                    </li>
                    <li>FHIR<sup>&reg;</sup> Schema: <a href="https://hl7.org/implement/standards/fhir/2015Jan/downloads.html">https://hl7.org/implement/standards/fhir/2015Jan/downloads.html</a>
                    </li>
                </ul>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="api-usage">Recommended methods for using the API</h1>
                <ApiSamples {...{apiKey}}/>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="api-samples">How many results will I get per call in the API</h1>
                <ApiPaging/>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="query-parameters">Query parameter value items and definitions</h1>
                <br/>
                <ol>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">branch-code</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">healthpoint-service-type</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">services-provided-type</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">specialty</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">programme-region</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">programme-area</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">programme-type</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">programme-age-groups</a></li>
                    <li><a href="/params/branch-code.pdf" target="_blank" rel="noopener noreferrer">programme-referral-type</a></li>
                    <li><a href="/params/region.pdf" target="_blank" rel="noopener noreferrer">region</a></li>
                    <li><a href="/params/dhb-region.pdf" target="_blank" rel="noopener noreferrer">dhb-region</a></li>
                    <li><a href="/params/subregion.pdf" target="_blank" rel="noopener noreferrer">subregion</a></li>
                </ol>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="data-samples">Other elements and value sets</h1>
                <br/>
                <ol>
                    <li><a href="/params/disability-assistance-type.pdf" target="_blank" rel="noopener noreferrer">disability-assistance-type</a></li>
                    <li><a href="/params/extra-details.pdf" target="_blank" rel="noopener noreferrer">extra-details</a></li>
                    <li><a href="/params/age-groups.pdf" target="_blank" rel="noopener noreferrer">age-groups</a></li>
                    <li><a href="/params/communication.pdf" target="_blank" rel="noopener noreferrer">communication</a></li>
                    <li><a href="/params/how-to-access.pdf" target="_blank" rel="noopener noreferrer">how-to-access</a></li>
                </ol>
            </div>

            <br/>
            <br/>
            <div>
                <h1 id="data-samples">Data Samples</h1>
                <DataSamples apiGw={apiGw} />
            </div>
        </div>
        );
};

export default Component;