// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as R from 'ramda';
import { Navigate, Routes, Route } from 'react-router-dom';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { Provider, useSelector } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import Home from './pages/Home';
import InternetExplorer from './pages/InternetExplorer';
import DashboardPage from './pages/DashboardPage';
import ApiOverview from './pages/ApiOverview';
import GetStarted from './pages/GetStarted';
import AdminUsersPage from './pages/AdminUsersPage';
import TermsAndConditions from './pages/TermsAndConditions';
import ApiUpdates from './pages/ApiUpdates';
import DocumentationPage from './pages/DocumentationPage';
import LoginPage from './pages/LoginPage';
import PasswordChangePage from './pages/PasswordChangePage';
import PasswordResetPage from './pages/PasswordResetPage';
import RegistrationPage from './pages/RegistrationPage';
import OpenApiPage from './pages/OpenApiPage';
import NavBar from './components/NavBar';
import { setupStore } from './common/state';
import { appStart } from './common/state/ducks/awsCognito/actions';
import { history } from './common/utils';

export const store = setupStore();

const isIE = () => {
    const ua = navigator.userAgent;
    const  is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    return is_ie;
}

export const AuthRoute = ({component: Component, ...props}) => {
    const {isPagePublicOnly,isAdminPage,isAdminUser,email,userSession} = props;
    if (R.isEmpty(userSession)) {
        return (<Component {...props} />);
    }
    if (email) {
        const isUserPageAuthed = !isAdminPage || isAdminUser ;
        return isUserPageAuthed ? <Component {...props} /> : <Navigate to="/" />;
    } else {
        return isPagePublicOnly ? <Component {...props} /> : <Navigate to="/login" />;
    }
};

const App = () => {
    const userSession = useSelector(state => state.cognito.userSession)
    const isAdminUser = useSelector(state => state.cognito.isAdminUser || false)
    const isAuthenticated = useSelector(state => state.cognito.isAuthenticated);
    const accountDetails = useSelector(state => state.cognito.accountDetails);
    const tncAccepted = useSelector(state => state.apiGw.tncAccepted);

    const email = useSelector(state => R.path(["accountDetails", "email"], state.cognito))

    // run once
    useEffect(() => {
        appStart();
    }, []);

    // run on EVERY render
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
    <ReduxRouter history={history}>
        <React.Fragment>
            <ReduxToastr
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
            />
            <NavBar/>
            <div className="container">
                {isIE() && <InternetExplorer/>}
                {!isIE() &&
                <Routes>
                    <Route end path="/" element={<Home /> }/>
                    <Route path="/get-started" element={<GetStarted />} />
                    <Route path="/api-overview" element={<ApiOverview />} />
                    <Route path="/updates" element={<ApiUpdates />} />

                    <Route path="/admin" element={<AuthRoute {...{component: AdminUsersPage, email, userSession, isAdminUser, isAdminPage:true }} />} />
                    <Route path="/open-api" element={<AuthRoute {...{component: OpenApiPage, email, userSession}} />} />
                    <Route path="/documentation" element={<AuthRoute {...{component: DocumentationPage, email, userSession}} />} />
                    <Route path="/dashboard" element={<AuthRoute {...{component: DashboardPage, email, userSession}} />} />
                    <Route path="/terms-and-conditions" element={<AuthRoute {...{component: TermsAndConditions, email, userSession, isAuthenticated, accountDetails, tncAccepted}}  />} />
                    <Route path="/login" element={<AuthRoute {...{component: LoginPage, email, userSession, isPagePublicOnly: true}} />} />
                    <Route path="/register" element={<AuthRoute {...{component: RegistrationPage, email, userSession, isPagePublicOnly: true}} />} />
                    <Route path="/password-reset" element={<AuthRoute {...{component: PasswordResetPage, email, userSession, isPagePublicOnly: true}} />} />
                    <Route path="/password-change" element={<AuthRoute {...{component: PasswordChangePage, email, userSession, isPagePublicOnly: true}} />} />
                    <Route element={({userSession}) => (R.isNil(userSession) ?
                            <Navigate to="/login" /> :
                            <Navigate to="/" />
                    )}/>
                </Routes>
                }
            </div>
        </React.Fragment>
    </ReduxRouter>
    );
};

const root = createRoot(document.getElementById('main'));
root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

