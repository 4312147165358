import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import SelectCheckbox from './SelectCheckbox';
import SelectCreatable from './SelectCreatable';
import SelectOption from './SelectOption';
import SelectRadio from './SelectRadio';
import { InputType } from '../../common/utils/constants';

const InputComponent = ({ type, id, value, onChange, onBlur, disabled, placeholder, ...props }) => {
  // console.dir({ type, id, value, onChange, onBlur, disabled, ...props });
  const name = id;
  switch (type) {
    case InputType.OPTIONS:
    case InputType.OPTION:
      return (
        <SelectCreatable
          {...{
            ...props,
            id,
            name,
            value,
            onChange,
            onBlur,
            disabled,
            initValue: value,
            multi: InputType.OPTIONS === type,
            options: props.options,
            placeholder: props.placeholder,
            createOption: props.createOption,
          }}
        />
      );
    case InputType.SELECT:
      return (
        <SelectOption
          {...{
            id,
            name,
            value,
            onChange,
            onBlur,
            disabled,
            options: props.options,
          }}
        />
      );
    case InputType.RADIOSELECT:
      return (
        <SelectRadio
          {...{
            id,
            name,
            value,
            onChange,
            onBlur,
            disabled,
            options: props.options,
          }}
        />
      );
    case InputType.CHECKBOXSELECT:
      return (
        <SelectCheckbox
          {...{
            id,
            name,
            value,
            onChange,
            onBlur,
            disabled,
            options: props.options,
          }}
        />
      );
    case InputType.CHECKBOX:
      return (
        <Input
          {...{
            type,
            id,
            name,
            checked: !!value,
            onChange,
            onBlur,
            disabled,
          }}
        />
      );
    case InputType.DATE:
      const date = value ? moment(value).format('YYYY-MM-DD') : '';
      return (
        <Input
          {...{
            type,
            id,
            name,
            value: date,
            onChange,
            onBlur,
            disabled,
          }}
        />
      );
    case InputType.TEL:
      return (
        <Input
          {...{
            type,
            id,
            name,
            placeholder,
            value: value || '',
            onChange,
            onBlur,
            disabled
          }}
        />
      );
    default:
      return (
        <Input
          {...{
            type,
            id,
            name,
            placeholder,
            value: value || '',
            onChange,
            onBlur,
            disabled,
          }}
        />
      );
  }
};

InputComponent.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
export default InputComponent;
