import React from 'react';
import {NavLink} from "react-router-dom";

const RegistrationPage = ({
    // Getter for the email address of the registered user.
    isRegistered,
}) => {
    const mailto = `mailto:${isRegistered}}`;
    return (<div>
        <h1>Thank you for registering!</h1>
        <hr/>
        <p>
            We have e-mailed you at
            {' '}<a target="_blank" rel="noopener noreferrer" href={mailto}>{isRegistered}</a>{' '}
            a link which you can click on to confirm your e-mail address,
            then you can <NavLink to="/login">log in</NavLink>.
        </p>
        <p>
            Please also check your spam folders.
        </p>
    </div>);
};

export default RegistrationPage;
